import { Maybe } from 'graphql/jsutils/Maybe'
import { ContentfulClientOptions, getContentfulClient, getWebClient } from '@/services/ApolloClient'
import { GetFranchiseBySlugQuery, GetLightFranchiseBySlugQuery } from '@/types/codegen-federation'
import { logger } from '@/utils/logging'
import { formatOrderSuffix } from '@/utils/numbers'
import { TranslateFunction } from '@/utils/translate/translate-client'
import { isStreaming } from '../PhaseManagerService'
import { Episode, Project } from '../ProjectsService'
import { GET_FRANCHISE_BY_SLUG, GET_FRANCHISE_PAGE_COLLECTION, GET_LIGHT_FRANCHISE_BY_SLUG } from './queries'

interface GetFranchisePageBySlugArgs {
  slug: string
  opts: ContentfulClientOptions
}

export type FranchisePageData = {
  slug: string
  heroBackgroundVideo: Maybe<string>
  shopifyStore: Maybe<{
    storeUrl: Maybe<string>
    accessToken: Maybe<string>
    handle: Maybe<string>
    storeHref: Maybe<string>
  }>
  posterHref: Maybe<string>
  posterDesktop: Maybe<{
    item: Array<{
      url: string
    }>
  }>
  posterTablet: Maybe<{
    item: Array<{
      url: string
    }>
  }>
  posterMobile: Maybe<{
    item: Array<{
      url: string
    }>
  }>
  projectsCollection: {
    items: Array<{
      slug: string
      franchiseWatchOrderText: string
    }>
  }
  logoOverride: Maybe<{
    item: Array<{
      url: string
    }>
  }>
}

export type LightFranchise = NonNullable<GetLightFranchiseBySlugQuery>['franchiseBySlug']

export type FranchiseExtended = NonNullable<GetFranchiseBySlugQuery>['franchiseBySlug']

export const getFranchisePageBySlug = async ({
  slug,
  opts,
}: GetFranchisePageBySlugArgs): Promise<FranchisePageData | null> => {
  const contentfulClient = getContentfulClient({
    preview: opts.preview,
    locale: opts.locale,
  })

  try {
    const { data } = await contentfulClient.query({
      query: GET_FRANCHISE_PAGE_COLLECTION,
      variables: {
        preview: opts.preview,
        slug,
      },
      fetchPolicy: 'network-only',
    })
    return data?.franchisePageCollection?.items?.[0] ?? null
  } catch (error) {
    logger().warn(`Error fetching franchise page by slug: ${slug}`, error)
    return null
  }
}

export const getFranchiseBySlug = async (slug: string, includePrelease: boolean): Promise<FranchiseExtended | null> => {
  const client = getWebClient()

  try {
    const { data } = await client.query({
      query: GET_FRANCHISE_BY_SLUG,
      variables: {
        slug,
        includePrelease,
      },
      errorPolicy: 'all',
    })
    const franchise = data?.franchiseBySlug ?? null

    return franchise
  } catch (error) {
    logger().warn(`Error fetching franchise by slug: ${slug}`, error)
    return null
  }
}

export const getLightFranchiseBySlug = async (slug: string): Promise<LightFranchise | null> => {
  const client = getWebClient()

  try {
    const { data } = await client.query({
      query: GET_LIGHT_FRANCHISE_BY_SLUG,
      variables: {
        slug,
      },
      errorPolicy: 'all',
    })
    const franchise = data?.franchiseBySlug ?? null

    return franchise
  } catch (error) {
    logger().warn(`Error fetching light franchise by slug: ${slug}`, error)
    return null
  }
}

export const formatDate = (date: Date, locale: string, options: Intl.DateTimeFormatOptions): string => {
  return date.toLocaleString(locale, options)
}

export const formatDateWithSuffix = (date: Date, locale: string): string => {
  return (
    date.toLocaleString(locale, { month: 'short', day: 'numeric', timeZone: 'UTC' }) +
    (locale === 'en' ? formatOrderSuffix(date.getUTCDate()) : '')
  )
}

const getTheatricalSubtitle = (
  item: Project,
  t: TranslateFunction,
  locale: string,
  releaseDate: Date,
  theatricalEndDate: Date,
  currentDate: Date,
  theatricalReleaseSpecificity?: string | null,
): string => {
  if (theatricalReleaseSpecificity && theatricalReleaseSpecificity === 'P1M') {
    return t('inTheatersOn', 'In Theaters {{ date }}', {
      date: formatDate(releaseDate, locale, { month: 'short', year: 'numeric', timeZone: 'UTC' }),
    })
  } else if (theatricalReleaseSpecificity && theatricalReleaseSpecificity === 'P3M') {
    return t('inTheaters', 'In Theaters') + ' ' + getSeasonNameForP3M(item, t, locale, releaseDate) || ''
  } else if (releaseDate < currentDate && theatricalEndDate > currentDate) {
    return t('inTheatersNowComingSoon', 'In Theaters Now')
  } else {
    return t('inTheatersOn', 'In Theaters {{ date }}', {
      date: formatDateWithSuffix(releaseDate, locale),
    })
  }
}

const getGuildAccessSubtitle = (
  item: Project,
  t: TranslateFunction,
  locale: string,
  releaseDate: Date,
  currentDate: Date,
  isGuildMember: boolean,
  guildAccessSpecificity?: string | null,
): string => {
  if (guildAccessSpecificity && guildAccessSpecificity === 'P1M') {
    return t('streamingForGuildMembersDate', 'Streaming for Guild Members {{ date }}', {
      date: formatDate(releaseDate, locale, { month: 'short', year: 'numeric', timeZone: 'UTC' }),
    })
  } else if (guildAccessSpecificity && guildAccessSpecificity === 'P3M') {
    return (
      t('streamingForGuildMembers', 'Streaming for Guild Members') +
        ' ' +
        getSeasonNameForP3M(item, t, locale, releaseDate) || ''
    )
  } else if (releaseDate < currentDate && isGuildMember) {
    return t('nowStreaming', 'Now Streaming')
  } else if (releaseDate < currentDate) {
    return t('nowStreamingForGuildMembers', 'Now Streaming for Guild Members')
  } else {
    return t('streamingForGuildMembersDate', 'Streaming for Guild Members {{ date }}', {
      date: formatDateWithSuffix(releaseDate, locale),
    })
  }
}

export const buildSubtitle = (item: Project, t: TranslateFunction, locale: string, isGuildMember: boolean): string => {
  const currentDate = new Date()
  const theatricalReleaseDate =
    item?.primaryFlowPhases?.find((phase) => phase?.phaseSlugEnum === 'theatrical')?.releaseWindows?.[0]?.start ?? null
  const theatricalReleaseSpecificity =
    item?.primaryFlowPhases?.find((phase) => phase?.phaseSlugEnum === 'theatrical')?.releaseWindows?.[0]
      ?.startSpecificity ?? null
  const theatricalEnd =
    item?.primaryFlowPhases?.find((phase) => phase?.phaseSlugEnum === 'theatrical')?.releaseWindows?.[0]?.end ?? null
  if (theatricalReleaseDate) {
    const releaseDate = new Date(theatricalReleaseDate)
    const theatricalEndDate = new Date(theatricalEnd || 0)
    return getTheatricalSubtitle(
      item,
      t,
      locale,
      releaseDate,
      theatricalEndDate,
      currentDate,
      theatricalReleaseSpecificity,
    )
  } else {
    const guildAccessDate =
      item?.primaryFlowPhases?.find((phase) => phase?.phaseSlugEnum === 'guild_access')?.releaseWindows?.[0]?.start ??
      null
    const guildAccessSpecificity =
      item?.primaryFlowPhases?.find((phase) => phase?.phaseSlugEnum === 'guild_access')?.releaseWindows?.[0]
        ?.startSpecificity ?? null
    if (guildAccessDate) {
      const releaseDate = new Date(guildAccessDate)
      return getGuildAccessSubtitle(item, t, locale, releaseDate, currentDate, isGuildMember, guildAccessSpecificity)
    } else if (isStreaming(item?.primaryFlowPhases ?? [])) {
      return t('nowStreaming', 'Now Streaming')
    } else {
      return t('toBeAnnounced', 'To Be Announced')
    }
  }
}

export function getSeasonNameForP3M(
  item: Project,
  t: TranslateFunction,
  locale: string,
  releaseDate: Date,
): string | null {
  const monthIndex = releaseDate.getMonth()

  switch (monthIndex) {
    case 0:
    case 1:
      return `${t('early', 'Early')} ${releaseDate.toLocaleString(locale, { year: 'numeric', timeZone: 'UTC' })}`
    case 2:
    case 3:
    case 4:
      return `${t('spring', 'Spring')} ${releaseDate.toLocaleString(locale, { year: 'numeric', timeZone: 'UTC' })}`
    case 5:
    case 6:
    case 7:
      return `${t('summer', 'Summer')} ${releaseDate.toLocaleString(locale, { year: 'numeric', timeZone: 'UTC' })}`
    case 8:
    case 9:
      return `${t('fall', 'Fall')} ${releaseDate.toLocaleString(locale, { year: 'numeric', timeZone: 'UTC' })}`
    case 10:
    case 11:
      return `${t('holidays', 'Holidays')} ${releaseDate.toLocaleString(locale, {
        year: 'numeric',
        timeZone: 'UTC',
      })}`
    default:
      return null
  }
}

export const canStreamEpisode = (isGuildMember: boolean, episode?: Episode): boolean => {
  if (!episode) {
    return false
  }
  const now = new Date()
  return Boolean(
    (episode?.publiclyAvailableDate && new Date(episode.publiclyAvailableDate) <= now) ||
      (isGuildMember && episode?.earlyAccessDate && new Date(episode.earlyAccessDate) <= now),
  )
}
